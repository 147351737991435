.instructionBody::-webkit-scrollbar {
    width: 6px;
}
  
.instructionBody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
  
.instructionBody::-webkit-scrollbar-thumb {
    background-color: #4d5e72;
    height: 2px;
    border-radius: 30px;
}

.containerTest::-webkit-scrollbar { 
    display: none; 
}
  