.subHeading {
    color: #c4c5c5;
    text-align: center;
    margin-bottom: 20px;
}

.avatarWrapper {
    width: 110px;
    height: 110px;
    border: 6px solid #0077ff;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}