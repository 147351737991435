.App{
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    background-color: #011936;
    }
    .democlass{
        /* width: 100%; */
    }
    .main{
    display: flex;
    height: calc(100vh - 50px);
    }
    .left-container{
    position: relative;
    flex: 70%;
    height: calc(100vh - 50px);
    }
    .right-container{
    /* flex: 30%; */
    /* height: calc(100vh - 50px); */
    display: flex;
    flex-direction: column;
    background-color: #011936;
    border-left: 3px solid #1f65e6;
    padding: 5px;
    }
    .input-box{
    flex: 50%;
    }
    .input-box textarea{
    font-size: 16px;
    }
    .spinner-box{
    flex: 50%;
    background-color: #242424;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .spinner-box img{
    width: 200px;
    }
    .output-box{
    flex: 50%;
    background-color: #242424;
    overflow-y: auto;
    color: white;
    position: relative;
    }
    .clear-btn{
    position: absolute;
    bottom: 14px;
    right: 18px;
    width: 80px;
    height: 40px;
    font-size: 22px;
    font-weight: bold;
    color: white;
    background-color: #1f65e6;
    border: none;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    }
    .output-box pre{
    font-size: 15px;
    white-space: pre-wrap;
    }
    h4{
    color: #afec3f;
    }
    #code-inp{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    resize: none;
    background-color: #242424;
    color: whitesmoke;
    padding: 5px;
    }
    #code-inp:focus{
    outline: none;
    }
    .run-btn{
    position: absolute;
    bottom: 10px;
    right: 100px;
    width: 80px;
    height: 40px;
    font-size: 22px;
    font-weight: bold;
    background-color: #afec3f;
    border: none;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    }
    .run-btn:active{
    background-color: #6e9427;
    }
    .run-btn1{
        position: absolute;
        bottom: 10px;
        right: 18px;
        width: 80px;
        height: 40px;
        font-size: 22px;
        font-weight: bold;
        background-color: #3f87ec;
        border: none;
        border-radius: 4px;
        transition: 0.3s;
        cursor: pointer;
        }
    